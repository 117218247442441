import { FC } from 'react';
import { IDevelopmentProcessItem } from '@components/main-page/main-page.state';
import Image from 'next/image';
import styles from './index.module.scss';

type DevelopmentProcessSectionProps = {
    title: string;
    items: IDevelopmentProcessItem[];
}

const DevelopmentProcessSection: FC<DevelopmentProcessSectionProps> = ({ title, items }) => {
    return (
        <div className={styles.container}>
            <section className={styles.section}>
                <h2 className={styles.section__title}>{title}</h2>
                <div className={styles.section__content}>
                    {items.map(({ title, description, imgSrc }) => (
                        <div key={title} className={styles.section__block}>
                            <div className={styles.section__block_image}>
                                <Image
                                    loading='lazy'
                                    src={imgSrc}
                                    alt={title}
                                    layout={'fixed'}
                                    width={60}
                                    height={60}
                                />
                            </div>
                            <div className={styles.section__block_text}>
                                <h3 className={styles.section__block_title}>{title}</h3>
                                <p className={styles.section__block_description}>{description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )
}

export default DevelopmentProcessSection;
